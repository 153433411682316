var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import cx from 'classnames';
import useStyles from './styles';
var Expand = function (_a) {
    var className = _a.className, props = __rest(_a, ["className"]);
    return (_jsx("span", __assign({ className: cx(useStyles().jobDoneIcon, className) }, props, { children: _jsxs("svg", __assign({ width: '24', height: '24', viewBox: '0 0 24 24', fill: 'none', xmlns: 'http://www.w3.org/2000/svg' }, { children: [_jsxs("g", __assign({ clipPath: 'url(#clip0_57516_373331)' }, { children: [_jsx("path", { d: 'M16 3H8C5.23858 3 3 5.23858 3 8V16C3 18.7614 5.23858 21 8 21H16C18.7614 21 21 18.7614 21 16V8C21 5.23858 18.7614 3 16 3Z', stroke: '#7F8897', strokeWidth: '2', strokeLinecap: 'round', strokeLinejoin: 'round' }, void 0), _jsx("path", { d: 'M12.5 8.5H15.5V11.5', stroke: '#7F8897', strokeWidth: '2', strokeLinecap: 'round', strokeLinejoin: 'round' }, void 0), _jsx("path", { d: 'M11.5 15.5H8.5V12.5', stroke: '#7F8897', strokeWidth: '2', strokeLinecap: 'round', strokeLinejoin: 'round' }, void 0)] }), void 0), _jsx("defs", { children: _jsx("clipPath", __assign({ id: 'clip0_57516_373331' }, { children: _jsx("rect", { width: '24', height: '24', fill: 'white' }, void 0) }), void 0) }, void 0)] }), void 0) }), void 0));
};
export default Expand;
