import { makeStyles } from '@material-ui/core';
export var useStyles = makeStyles(function (theme) { return ({
    container: {
        // background: theme.palette.common.white,
        boxSizing: 'border-box',
        width: '100%',
        height: '100%',
        minHeight: '20px',
        borderRadius: 8,
        border: 'none',
        cursor: 'pointer',
        position: 'relative',
        '&:hover': {
            borderColor: theme.palette.secondary.dark,
            background: theme.palette.secondary.dark,
        },
        '&:hover $hoverBlock': {
            opacity: 1,
        },
    },
    disabled: {
        background: theme.palette.grey[100],
        borderColor: theme.palette.grey[300],
        cursor: 'default',
        '&:hover': {
            background: theme.palette.grey[100],
            borderColor: theme.palette.grey[300],
        },
    },
    hoverBlock: {
        position: 'absolute',
        top: 0,
        padding: 0,
        outline: 'none',
        overflow: 'none',
        border: 'none',
        background: 'transparent',
        cursor: 'pointer',
        opacity: 0,
        borderRadius: 6,
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        '& svg': {
            width: 14,
            height: 14,
        },
        '& path': {
            fill: theme.palette.primary.light,
        },
    },
    menuItem: {
        display: 'flex',
        gap: 10,
    },
    skeletonBlock: {
        minHeight: '20px',
        borderRadius: 8,
    },
    isFlashMode: {
        '& $overlay': {
            background: 'rgba(224, 0, 11, 0.85)',
            margin: 2,
            borderRadius: 4,
            width: 'calc(100% - 4px)',
            height: 'calc(100% - 4px)',
            gap: '2px',
            cursor: 'pointer',
        },
        '&:hover': {
            '--angle': '0deg',
            padding: 2,
            border: '2px solid transparent !important',
            boxSizing: 'border-box',
            backgroundOrigin: 'border-box',
            backgroundImage: 'conic-gradient(from var(--angle), #3C5AF9 0%, #F96955 4.69%, #FD87AA 8.85%, #FCD5AD 16.15%, #E97584 23.96%, #FED556 31.77%, #FED655 40.63%, #FFF4F2 48.44%, #F2CDFE 54.69%, #FD99F9 63.54%,  #D693FA 69.79%, #E492E4 77.08%, #1E9AFA 83.85%, #8CFBD7 90.63%, #2E50F6 100%)',
            boxShadow: "1px 1000px 1px " + theme.palette.common.white + " inset",
            animation: '$rotate 2s linear infinite',
        },
        '&:hover $unPublished': {
            background: 'none',
        },
    },
    '@keyframes rotate': {
        to: {
            '--angle': '360deg',
        },
    },
    whiteColor: {
        color: theme.palette.common.white,
    },
}); });
