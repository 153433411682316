var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { forwardRef } from 'react';
import { Dialog as DialogComponent, DialogActions, DialogContent, Slide, makeStyles, } from '@material-ui/core';
import cx from 'classnames';
import { Button } from '../Button';
import { Header, Caption } from '../Typography';
import { Icons, NavigationBar } from '../..';
var Transition = forwardRef(function (props, ref) { return (_jsx(Slide, __assign({ direction: 'up', ref: ref }, props), void 0)); });
var useStyles = makeStyles(function (theme) { return ({
    root: {
        boxShadow: '3px 12px 32px rgba(22, 36, 61, 0.14)',
        borderRadius: 16,
        padding: 32,
    },
    actions: {
        display: 'flex',
        justifyContent: 'center',
        padding: 0,
    },
    content: {
        padding: 0,
        marginBottom: 20,
    },
    titleRoot: {
        marginBottom: 20,
        display: 'flex',
        justifyContent: 'space-between',
        marginTop: 4,
        padding: 0,
    },
    closeIcon: {
        color: theme.palette.grey[700],
    },
    text: {
        color: theme.palette.grey[700],
    },
    error: {
        margin: '0 auto 10px',
        color: theme.palette.error.main,
    },
    navigationBar: {
        '& button': {
            marginRight: 16,
        },
    },
    navigationTitleContainer: {
        display: 'flex',
        flexDirection: 'column',
    },
    navigationTitle: {
        fontSize: 18,
        lineHeight: '24px',
    },
    navigationSubTitle: {
        color: theme.palette.grey[500],
    },
    navigationBarContent: {
        marginTop: 72,
    },
    redBtn: {
        backgroundColor: theme.palette.error.main,
        '&:hover': {
            backgroundColor: '#f76a71',
        },
        '&:active': {
            backgroundColor: theme.palette.error.main,
        },
    },
}); });
export var Dialog = function (_a) {
    var _b;
    var locale = _a.locale, open = _a.open, handleClose = _a.handleClose, handleConfirm = _a.handleConfirm, handleDecline = _a.handleDecline, handleSecondaryButtonClick = _a.handleSecondaryButtonClick, disableHandleKeyDown = _a.disableHandleKeyDown, isLoading = _a.isLoading, children = _a.children, disabled = _a.disabled, _c = _a.hideActionButtons, hideActionButtons = _c === void 0 ? false : _c, _d = _a.hideTopBar, hideTopBar = _d === void 0 ? false : _d, _e = _a.headerType, headerType = _e === void 0 ? 'text' : _e, paperClassName = _a.paperClassName, maxWidth = _a.maxWidth, isConfirmButtonRed = _a.isConfirmButtonRed, renderAdditionalActions = _a.renderAdditionalActions, props = __rest(_a, ["locale", "open", "handleClose", "handleConfirm", "handleDecline", "handleSecondaryButtonClick", "disableHandleKeyDown", "isLoading", "children", "disabled", "hideActionButtons", "hideTopBar", "headerType", "paperClassName", "maxWidth", "isConfirmButtonRed", "renderAdditionalActions"]);
    var classes = useStyles();
    var handleKeyDown = function (event) {
        if (!disableHandleKeyDown && event.key === 'Enter' && handleConfirm && !isLoading && !(disabled || false)) {
            handleConfirm();
        }
    };
    return (_jsx(_Fragment, { children: _jsxs(DialogComponent, __assign({}, props, { TransitionComponent: Transition, "data-testid": 'ui-lib-dialog-component', open: open, maxWidth: maxWidth || 'xl', classes: {
                paper: cx([classes.root, paperClassName]),
            }, onClose: handleClose, onKeyDown: handleKeyDown }, { children: [!hideTopBar && (headerType === 'navigationBar' ? (_jsxs(NavigationBar, __assign({ className: classes.navigationBar }, { children: [_jsx(Button, { disabled: isLoading, onClick: handleClose, variant: 'fixedIcon', color: 'ghost', "data-testid": 'ui-lib-close-icon-btn', icon: _jsx(Icons.ArrowLeft, { className: classes.closeIcon }, void 0) }, void 0), _jsxs("div", __assign({ className: classes.navigationTitleContainer }, { children: [_jsx(Header, __assign({ className: classes.navigationTitle }, { children: locale.title }), void 0), _jsx(Caption, __assign({ variant: 'regular', className: classes.navigationSubTitle }, { children: locale.subtitle }), void 0)] }), void 0)] }), void 0)) : (_jsxs("div", __assign({ className: classes.titleRoot }, { children: [_jsx(Header, __assign({ size: 24 }, { children: locale.title }), void 0), _jsxs("div", __assign({ className: 'flex items-center' }, { children: [renderAdditionalActions && renderAdditionalActions(), _jsx(Button, { disabled: isLoading, onClick: handleClose, variant: 'fixedIcon', color: 'ghost', "data-testid": 'ui-lib-close-icon-btn', icon: _jsx(Icons.Close, { className: classes.closeIcon }, void 0) }, void 0)] }), void 0)] }), void 0))), _jsx(DialogContent, __assign({ className: cx([
                        classes.content,
                        (_b = {},
                            _b[classes.navigationBarContent] = headerType === 'navigationBar',
                            _b),
                    ]) }, { children: (locale === null || locale === void 0 ? void 0 : locale.content) || children }), void 0), (locale === null || locale === void 0 ? void 0 : locale.errorMessage) && (_jsx(Caption, __assign({ className: classes.error }, { children: locale.errorMessage }), void 0)), !hideActionButtons && (_jsxs(DialogActions, __assign({ className: classes.actions }, { children: [locale.declineButton && (_jsx(Button, { onClick: function () {
                                if (handleDecline) {
                                    handleDecline();
                                }
                                else if (handleClose) {
                                    handleClose();
                                }
                            }, disabled: isLoading, label: locale.declineButton, color: 'ghost', size: 'large', "data-testid": 'ui-lib-close-btn' }, void 0)), locale.secondaryButton && (_jsx(Button, { onClick: handleSecondaryButtonClick, disabled: disabled || isLoading, label: locale.secondaryButton, loading: isLoading, size: 'large' }, void 0)), locale.confirmButton && (_jsx(Button, { onClick: handleConfirm, disabled: disabled || isLoading, label: locale.confirmButton, loading: isLoading, className: isConfirmButtonRed ? classes.redBtn : '', size: 'large' }, void 0))] }), void 0))] }), void 0) }, void 0));
};
