var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import cx from 'classnames';
import { Skeleton } from '../../../Skeleton';
import { useStyles } from './EmptyEntry.styles';
import { useDropdownMenu } from '../../../../utils/helpers';
import { EntryMenuItems } from '../../MenuOptions';
import { emptyCellMenuItems } from '../../MenuOptions/options';
import { PasteIcon, Plus } from '../../../icons';
export var EmptyEntry = function (_a) {
    var _b;
    var testId = _a.testId, _c = _a.disabled, disabled = _c === void 0 ? false : _c, loading = _a.loading, _d = _a.isOpenShift, isOpenShift = _d === void 0 ? false : _d, _e = _a.isFlashMode, isFlashMode = _e === void 0 ? false : _e, _f = _a.isCopyMode, isCopyMode = _f === void 0 ? false : _f, onClick = _a.onClick, menuItems = _a.menuItems, children = _a.children, props = __rest(_a, ["testId", "disabled", "loading", "isOpenShift", "isFlashMode", "isCopyMode", "onClick", "menuItems", "children"]);
    var classes = useStyles();
    var _g = useDropdownMenu(), handleClose = _g.handleClose, anchorEl = _g.anchorEl, handleClick = _g.handleClick;
    var containerClasses = cx(classes.container, (_b = {},
        _b[classes.disabled] = disabled,
        _b[classes.isFlashMode] = isFlashMode || isCopyMode,
        _b));
    if (loading) {
        return _jsx(Skeleton, { animation: false, variant: 'rect', className: classes.skeletonBlock }, void 0);
    }
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    var combineMenuItems = function () { return (menuItems === null || menuItems === void 0 ? void 0 : menuItems.map(function (menuItem) { return (__assign(__assign({}, emptyCellMenuItems.find(function (_a) {
        var key = _a.key;
        return key === menuItem.key;
    })), menuItem)); })) || []; };
    return (_jsxs(_Fragment, { children: [_jsxs("div", __assign({ "aria-label": 'empty-entry', className: containerClasses, "data-testid": testId }, props, { children: [children, !disabled && (_jsx("button", __assign({ "aria-label": 'add-button', onClick: function (e) {
                            // Important: e.detail === 0 prevents any click propagation with keyboard keys.
                            // Prevents accidental insertion of shifts/absences when typing enter when delete dialog is open
                            if (e.ctrlKey || e.metaKey || e.detail === 0) {
                                // Do not open the add menu when CTRL or CMD key is pressed
                                return;
                            }
                            var clickHandler = isFlashMode || isCopyMode ? onClick : handleClick;
                            if (clickHandler) {
                                clickHandler(e);
                            }
                        }, className: classes.hoverBlock }, { children: isCopyMode ? _jsx(PasteIcon, { "aria-label": 'paste-icon' }, void 0) : _jsx(Plus, { "aria-label": 'plus-icon' }, void 0) }), void 0))] }), void 0), _jsx(EntryMenuItems, { handleClose: handleClose, anchorEl: anchorEl, menuItems: combineMenuItems() }, void 0)] }, void 0));
};
