var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import useStyles from './styles';
var MarkAsRead = function (props) { return (_jsx("span", __assign({ className: useStyles().jobDoneIcon }, props, { children: _jsx("svg", __assign({ xmlns: 'http://www.w3.org/2000/svg', width: '24', height: '24', viewBox: '0 0 24 24', fill: 'none' }, { children: _jsx("g", __assign({ id: 'icon/mark_as_read' }, { children: _jsxs("g", __assign({ id: 'Union' }, { children: [_jsx("path", { fillRule: 'evenodd', clipRule: 'evenodd', d: 'M15.1483 3.17567C16.7159 1.60811 19.2574 1.60811 20.825 3.17567C22.3925 4.74324 22.3925 7.28476 20.825 8.85233C19.2574 10.4199 16.7159 10.4199 15.1483 8.85233C13.5808 7.28476 13.5808 4.74324 15.1483 3.17567ZM16.5625 4.58989C17.3491 3.80337 18.6243 3.80337 19.4108 4.58989C20.1973 5.3764 20.1973 6.6516 19.4108 7.43811C18.6243 8.22463 17.3491 8.22463 16.5625 7.43811C15.776 6.6516 15.776 5.3764 16.5625 4.58989Z', fill: 'currentColor' }, void 0), _jsx("path", { d: 'M4 7C4 6.44728 4.44728 6 5 6H11C11.5523 6 12 5.55228 12 5C12 4.44772 11.5523 4 11 4H5C3.34272 4 2 5.34272 2 7V19C2 20.6573 3.34272 22 5 22H17C18.6573 22 20 20.6573 20 19V13C20 12.4477 19.5523 12 19 12C18.4477 12 18 12.4477 18 13V19C18 19.5527 17.5527 20 17 20H5C4.44728 20 4 19.5527 4 19V7Z', fill: 'currentColor' }, void 0)] }), void 0) }), void 0) }), void 0) }), void 0)); };
export default MarkAsRead;
