var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { makeStyles } from '@material-ui/core/styles';
import cx from 'classnames';
import Linkify from 'linkify-react';
import { Avatar } from '../Avatar';
import { Caption } from '../Typography';
var useStyles = makeStyles(function (theme) { return ({
    root: {
        display: 'flex',
        alignItems: 'center',
        gap: 8,
        width: '100%',
    },
    container: {
        width: '100%',
        maxWidth: 680,
        wordBreak: 'break-word',
    },
    messageContainer: {
        border: "1px solid " + theme.palette.grey[100],
        borderRadius: 16,
        backgroundColor: 'white',
        padding: '8px 16px',
        width: '100%',
    },
    timeAndActionsContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        gap: 8,
        marginTop: 4,
    },
    group: {
        display: 'flex',
        flexDirection: 'column',
        wordBreak: 'break-word',
        '& > p': {
            color: theme.palette.common.black,
            fontFamily: theme.typography.fontFamily,
            fontWeight: 500,
            fontSize: '14px',
            lineHeight: '24px',
            '& > a': {
                color: theme.palette.primary.main,
            },
        },
    },
    time: {
        color: theme.palette.grey[500],
    },
    avatar: {
        display: 'flex',
        alignSelf: 'flex-start',
    },
}); });
var linkifyOptions = {
    target: '_blank',
    rel: 'noopener noreferrer',
};
var Comment = function (_a) {
    var children = _a.children, style = _a.style, className = _a.className, time = _a.time, userAvatar = _a.userAvatar, userName = _a.userName, actions = _a.actions, rest = __rest(_a, ["children", "style", "className", "time", "userAvatar", "userName", "actions"]);
    var classes = useStyles();
    return (_jsxs("div", __assign({ className: cx(className, classes.root), style: style }, rest, { children: [_jsx(Avatar, { containerClassName: classes.avatar, size: 's', src: userAvatar, placeHolder: userName, alt: userName }, void 0), _jsxs("div", __assign({ className: classes.messageContainer }, { children: [_jsxs("div", __assign({ className: cx(classes.container, classes.group) }, { children: [_jsx(Caption, __assign({ weight: 700 }, { children: userName }), void 0), _jsx(Linkify, __assign({ options: linkifyOptions }, { children: children }), void 0)] }), void 0), _jsxs("div", __assign({ className: classes.timeAndActionsContainer }, { children: [_jsx(Caption, __assign({ variant: 'bold', weight: 500, className: classes.time }, { children: time }), void 0), actions] }), void 0)] }), void 0)] }), void 0));
};
export default Comment;
