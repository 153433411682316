var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import Select, { components, } from 'react-select';
import cx from 'classnames';
import { Icons } from '../..';
import { useStyles, selectStyles } from './styles/Dropdown.styles';
import { FormControl } from '../FormControl/FormControl';
import { Avatar } from '../Avatar';
var Dropdown = function (_a) {
    var _b = _a.className, className = _b === void 0 ? '' : _b, hiddenLabel = _a.hiddenLabel, _c = _a.locale, locale = _c === void 0 ? { label: 'Label', errorMessage: 'Error', placeholder: 'Select...' } : _c, _d = _a.labelClassName, labelClassName = _d === void 0 ? '' : _d, _e = _a.labelId, labelId = _e === void 0 ? 'select-label' : _e, _f = _a.error, error = _f === void 0 ? false : _f, _g = _a.testId, testId = _g === void 0 ? 'dropdown' : _g, _h = _a.itemPerPageSelect, itemPerPageSelect = _h === void 0 ? false : _h, required = _a.required, _j = _a.containerClassName, containerClassName = _j === void 0 ? '' : _j, withAvatar = _a.withAvatar, hideCheckIcon = _a.hideCheckIcon, _k = _a.isSearchable, isSearchable = _k === void 0 ? false : _k, _l = _a.isMulti, isMulti = _l === void 0 ? false : _l, rest = __rest(_a, ["className", "hiddenLabel", "locale", "labelClassName", "labelId", "error", "testId", "itemPerPageSelect", "required", "containerClassName", "withAvatar", "hideCheckIcon", "isSearchable", "isMulti"]);
    var styles = useStyles({ error: error, itemPerPageSelect: itemPerPageSelect, isMulti: isMulti });
    var dropdownClassNames = cx(styles.select, className);
    var _m = __read(useState(false), 2), isActive = _m[0], setIsActive = _m[1];
    var handleFocus = function () {
        setIsActive(true);
    };
    var handeBlur = function () {
        setIsActive(false);
    };
    return (_jsx("div", __assign({ className: containerClassName }, { children: _jsx(FormControl, __assign({ active: isActive, hiddenLabel: hiddenLabel, error: error, errorMessage: locale.errorMessage, id: labelId + "-" + String(locale === null || locale === void 0 ? void 0 : locale.label), required: required, label: locale === null || locale === void 0 ? void 0 : locale.label }, { children: _jsx(Select, __assign({}, rest, { styles: selectStyles, className: dropdownClassNames, classNamePrefix: 'jobDone-select', placeholder: locale.placeholder, "data-testid": testId, onFocus: handleFocus, onBlur: handeBlur, isSearchable: isSearchable, isMulti: isMulti, components: {
                    ValueContainer: function (_a) {
                        var _b, _c, _d, _e;
                        var children = _a.children, props = __rest(_a, ["children"]);
                        var options = props.getValue();
                        return (_jsx(components.ValueContainer, __assign({}, props, { children: _jsxs("div", __assign({ className: styles.optionContainer }, { children: [withAvatar && (_jsx(Avatar, { "aria-label": 'dropdown-option-avatar', size: 's', className: styles.avatar, placeHolder: (_b = options[0]) === null || _b === void 0 ? void 0 : _b.label, src: (_c = options[0]) === null || _c === void 0 ? void 0 : _c.src }, void 0)), ((_d = options[0]) === null || _d === void 0 ? void 0 : _d.icon) && _jsx("div", __assign({ className: styles.icon }, { children: (_e = options[0]) === null || _e === void 0 ? void 0 : _e.icon }), void 0), children] }), void 0) }), void 0));
                    },
                    DropdownIndicator: function (props) { return (_jsx(components.DropdownIndicator, __assign({}, props, { children: _jsx(Icons.DropdownIcon, { className: styles.dropdownIndicator }, void 0) }), void 0)); },
                    Option: function (_a) {
                        var children = _a.children, props = __rest(_a, ["children"]);
                        var option = props.data;
                        return (_jsx(components.Option, __assign({}, props, { children: _jsxs("div", __assign({ className: styles.optionContainer }, { children: [withAvatar && (_jsx(Avatar, { "aria-label": 'dropdown-option-avatar', size: 's', className: styles.avatar, placeHolder: option.label, src: option.src }, void 0)), option.icon && _jsx("div", __assign({ className: styles.icon }, { children: option.icon }), void 0), children, props.isSelected && !hideCheckIcon && _jsx(Icons.GreenCheck, { className: styles.selectedOptionCheck }, void 0)] }), void 0) }), void 0));
                    },
                } }), void 0) }), void 0) }), void 0));
};
export default Dropdown;
